.c-404 {
  height: calc(100vh - 72px);
  margin-top: 72px;
  padding: 0 32px;
}

.c-404__heading {
  font-size: 160px;
  line-height: 160px;
}

.c-404__subheading {
  max-width: 835px;
}

@screen md {
  .c-404 {
    height: calc(100vh - 106px);
    margin-top: 106px;
  }
}
