.c-testimonials-slider-us__container {
    max-width: 1228px;
}

.c-testimonials-slider-us {
    position: relative;
    background: url("../../svg/forms/wave-testimonials-bottom.svg") no-repeat center / cover;
    z-index: 1;
    padding: 130px 0;
}

.c-testimonials-slider-us::after {
    content: '';
    background: url("../../svg/forms/wave-testimonials-top.svg") no-repeat center / cover;
    position: absolute;
    top: 8px;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-testimonials-slider-us__testimonial-blockquote > span {
    font-size: 63px;
    line-height: 0;
    position: relative;
    top: 10px;
}

.c-testimonials-slider-us__testimonial > div.relative > div > div {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.c-testimonials-slider-us__testimonial-blockquote {
    position: relative;
    padding: 0 20px;
}

.c-testimonials-slider-us__testimonial-blockquote > span:first-child {
    position: absolute;
    top: 35px;
    left: -10px;
    color: #10a8ba;
}
.c-testimonials-slider-us__testimonial-blockquote > span:last-child {
    top: 20px;
    left: 5px;
    color: #10a8ba;
}

@media screen and (max-width: 768px) {
    .c-testimonials-slider-us__disable-border-mobile.c-testimonials-slider-us, .c-testimonials-slider-us__disable-border-mobile.c-testimonials-slider-us::after {
        background: none;
        padding: 30px 0;
    }
}

@media screen and (min-width: 768px) {
    .c-testimonials-slider-us, .c-testimonials-slider-us::after {
        background-position: top center;
    }
}

@media screen and (min-width: 1024px) {
    .c-testimonials-slider-us__container .glide--slider::before, .c-testimonials-slider-us__container .glide--slider::after {
        content: '';
        position: absolute;
        opacity: 0.8;
        width: 120px;
        height: 100%;
        top: 0;
        z-index: 2;
    }

    .c-testimonials-slider-us__container .glide--slider::before {
        left: -1px;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%);
    }

    .c-testimonials-slider-us__container .glide--slider::after {
        right: -1px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }
}
