.m-pinned-subnav__toggle,
.m-pinned-subnav__button {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  min-height: 56px;

  @screen lg {
    min-height: auto;
  }
}

.m-pinned-subnav__toggle-icon {
  @nest [dir="rtl"] & {
    transform: rotate(180deg);
  }
}

.m-pinned-subnav__list {
  max-height: calc(100vh - var(--c-the-header-height, 72px));
}

.m-pinned-subnav__item {
  @screen lg {
    margin-inline-start: 3rem;

    &:first-child {
      margin-inline-start: 0;
    }
  }
}
