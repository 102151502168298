.c-photo-collage__image-container {
  @apply relative;

  margin-top: 1rem;
  width: 100%;
}

.c-photo-collage__ratio {
  @apply relative;

  height: 0;
  padding-top: 100%;
}

@screen sm {
  .c-photo-collage__image-container {
    margin-top: 0;
    width: 33.3333%;
  }

  .c-photo-collage__image-container--double {
    width: 66.6666%;
  }

  .c-photo-collage__image-container--double .c-photo-collage__ratio {
    padding-top: 50%;
  }
}

.c-photo-collage__image {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}
