.c-expanded-cta-section {
    position: relative;
    background: url("../../svg/forms/wave-expanded-cta-bottom-sm.svg") no-repeat center / cover;
    z-index: 1;
    padding: 175px 0;
    color: white;
    overflow: hidden;
}

.c-expanded-cta-section::after {
    content: '';
    background: url("../../svg/forms/wave-expanded-cta-top-sm.svg") no-repeat center / cover;
    position: absolute;
    top: 30px;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-expanded-cta-section__icons::after, .c-expanded-cta-section__icons::before {
    content: '';
    position: absolute;
    z-index: 1;
}

.c-expanded-cta-section__icons::before {
    width: 525px;
    height: 330px;
    top: 20%;
    left: calc(525px / 2.5 * -1);
    background: url('/img/lens-icon-bg.png') 50% / contain no-repeat;
}

.c-expanded-cta-section__copy h3 {
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: theme('fontWeight.semibold');
    font-size: theme('fontSize.2xl');
}

.c-expanded-cta-section__copy blockquote {
    font-size: theme('fontSize.sm');
    display: inline-block;
    margin-top: 40px;
    font-style: normal;
}

.c-expanded-cta-section > div > div > div.flex.justify-center > a > span {
    font-size: 18px;
}

@media screen and (min-width: 768px) {
    .c-expanded-cta-section {
        margin-top: -65px;
        background-image: url("../../svg/forms/wave-expanded-cta-bottom.svg");
    }

    .c-expanded-cta-section::after {
        background-image: url("../../svg/forms/wave-expanded-cta-top.svg");
        top: -24px;
        bottom: 20px
    }

    .c-expanded-cta-section__icons::after {
        width: 530px;
        height: 530px;
        top: -5px;
        left: calc(530px / 2 * -1);
        background: url('/img/eye-drop-icon-bg.png') 50% / contain no-repeat;
        opacity: 0.15;
        filter: brightness(0) invert(1);
    }

    .c-expanded-cta-section__icons::before {
        top: 25%;
        right: calc(525px / 2.5 * -1);
        left: unset;
    }
}

@media screen and (min-width: 1460px) {
    .c-expanded-cta-section::after {
        background-image: url("../../svg/forms/wave-expanded-cta-top.svg");
        top: 8px;
        bottom: 20px
    }
}
