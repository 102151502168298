.c-the-footer__newsletter {
  max-width: 640px;
}

.c-the-footer__links-item {
  @apply uppercase;
}

.c-the-footer__links-item::after {
  @apply bg-primary-gray;
  @apply mis-2;

  content: "";
  display: inline-block;
  height: 10px;
  width: 1px;
}

.c-the-footer__links-item:last-child::after {
  display: none;
}

.c-the-footer__menu-item {
  @apply font-bold text-button uppercase;

  color: #b7bbbc;
}

.c-the-footer__extra {
  @apply text-primary-gray;
}

.c-the-footer__extra a {
  @apply cursor-pointer font-semibold tracking-wider uppercase transition duration-300;
}

.c-the-footer__extra a:hover {
  @apply text-neutral-gray;
}

.c-the-footer__extra p {
  @apply pt-4;
}

.c-the-footer__loading-icon {
  animation: loading-icon-animation 1s infinite linear;
}

@keyframes loading-icon-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
