.c-legacy-navigation-evo__bar {
  height: 2px;
  left: 0;
  top: 50%;
}

.c-legacy-navigation-evo__bar--top {
  transform: translateY(calc(-9px - 50%));
}

.c-legacy-navigation-evo__bar--middle {
  transform: translateY(-50%);
}

.c-legacy-navigation-evo__bar--bottom {
  transform: translateY(calc(9px - 50%));
}

.active .c-legacy-navigation-evo__bar--top {
  transform: translateY(-50%) rotate(45deg);
}

.active .c-legacy-navigation-evo__bar--middle {
  opacity: 0;
}

.active .c-legacy-navigation-evo__bar--bottom {
  transform: translateY(-50%) rotate(-45deg);
}
