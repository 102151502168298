.a-button--size-md {
  border-radius: 20px / 50%;
  padding: 6px 22px;
}

.a-button--size-lg {
  border-radius: 24px / 50%;
  padding: 8px 30px;
}

.a-button--size-xl {
  border-radius: 28px / 50%;
  padding: 10px 46px;
}
