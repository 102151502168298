.c-image-section-break__image {
  height: 454px;
  transform: scale(1.1);
  transition: transform 0.4s ease-out;
}

@screen md {
  .c-image-section-break__image {
    height: 700px;
  }
}

.c-image-section-break--animate .c-image-section-break__image {
  transform: scale(1);
}