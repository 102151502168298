@screen lg {
  .c-background-image-list__container {
    height: 700px;
  }
}

.c-background-image-list__image-ratio {
  padding-top: 100%;
  width: 100%;
}

@screen sm {
  .c-background-image-list__image-ratio {
    padding-top: 0;
  }
}

.c-background-image-list__inner-container {
  max-width: calc(1128px + 3rem);
}

.c-background-image-list__item {
  @apply flex;
  @apply items-start;
  @apply text-primary-gray;
  @apply text-p;
  @apply font-semibold;

  margin-top: 1rem;
}

@screen md {
  .c-background-image-list__item {
    @apply items-center;
  }
}

@screen lg {
  .c-background-image-list__item {
    @apply text-neutral-white;
  }
}

.c-background-image-list__item:first-child {
  margin-top: 0;
}

.c-background-image-list__dot {
  @apply bg-primary-teal;
  @apply w-6;
  @apply h-6;
  @apply rounded-full;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply mie-4;
  @apply text-neutral-white;
  @apply flex-shrink-0;
}

@screen lg {
  .c-background-image-list__dot {
    @apply bg-neutral-white;
    @apply text-primary-teal;
    @apply w-8;
    @apply h-8;
  }
}

.c-background-image-list__dot svg {
  width: 17px;
}

.c-background-image-list__heading {
  max-width: 385px;
}

.c-background-image-list__subheading {
  max-width: 385px;
}

.c-background-image-list__copy-box {
  max-width: 520px;
}

.c-background-image-list__copy {
  font-size: 12px;
}
