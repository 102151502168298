.c-masthead__logo {
  width: 266px;
}

.c-masthead__blocks--fade {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative !important;
  }
}

.c-masthead__block--fade {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 1 !important;
    pointer-events: auto !important;
    position: relative !important;
  }
}

.c-masthead__block--media {
  filter: brightness(0.85);
}

.c-masthead__block--scale {
  transform: scaleX(-1);
}

.c-masthead__block--frames {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 1 !important;
    pointer-events: auto !important;

    @nest .c-masthead.is-active & {
      position: fixed !important;
    }
  }
}

.c-masthead__blocks--normal {
  margin-top: -100vh;
}

.c-masthead__body {
  max-width: 660px;
}

@screen md {
  .c-masthead__body--large {
    max-width: 904px;
  }

  .c-masthead__body--with-logo {
    max-width: 451px;
  }
}

.c-masthead__scroll-icon {
  transition: transform 0.2s ease-out;
}

.c-masthead__scroll-text:hover .c-masthead__scroll-icon {
  transform: translate3d(0, 5px, 0);
}

.c-masthead__spacer {
  height: 76px;
}
