@screen md {
  .c-about-video__container {
    height: 700px;
  }
}

.c-about-video__animation-container {
  height: 281px;
  width: 281px;
}

@screen md {
  .c-about-video__animation-container {
    height: 972px;
    width: 972px;
  }
}

@keyframes ring-animation {
  100% {
    transform: rotate(360deg);
  }
}

.c-about-video__rings {
  animation: ring-animation 60s linear infinite;
}

.c-about-video__copy {
  max-width: 312px;
}

.c-about-video__lens {
  height: 83px;
  width: 132px;
}

@screen md {
  .c-about-video__lens {
    height: 198px;
    width: 316px;
  }
}

.c-about-video__play-button {
  height: 46px;
  width: 46px;
}

@screen md {
  .c-about-video__play-button {
    height: 96px;
    width: 96px;
  }
}
