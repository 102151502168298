.c-story-gallery__slider {
  height: 291px;
}

.c-story-gallery__slides {
  clip-path: inset(100% 0 0 0);
  transition: clip-path 0.5s 0.3s;
}

.c-story-gallery--animate-down .c-story-gallery__slides {
  clip-path: inset(0);
}

.c-story-gallery__background {
  clip-path: inset(100% 0 0 0);
  transition: clip-path 0.5s;
}

.c-story-gallery--animate-down .c-story-gallery__background {
  clip-path: inset(0);
}

.c-story-gallery__copies {
  height: 300px;
  transition: height 0.2s ease-out;
}

.c-story-gallery__heading {
  opacity: 0;
  transition: opacity 0.5s;
}

.c-story-gallery--animate .c-story-gallery__heading {
  opacity: 1;
}

.c-story-gallery__copies-container {
  opacity: 0;
  transition: opacity 0.5s 0.2s;
}

.c-story-gallery--animate .c-story-gallery__copies-container {
  opacity: 1;
}

@screen lg {
  .c-story-gallery__copies {
    height: auto;
  }

  .c-story-gallery {
    height: 700px;
  }

  .c-story-gallery__content {
    width: calc(50% - 0.75rem);
  }

  .c-story-gallery__slider {
    height: auto;
    width: calc(50% - 0.75rem);
  }
}

.c-story-gallery__copy {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  transition-delay: 0s;
}

.c-story-gallery__copy--active {
  opacity: 1;
  transition-delay: 0.2s;
}

.c-story-gallery__controls-arrow-icon {
  @nest [dir="rtl"] & {
    transform: rotate(180deg);
  }
}
