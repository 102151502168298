.c-full-width-testimonial {
  height: 675px;
}

.c-full-width-testimonial__quote {
  max-width: 760px;
}

.c-full-width-testimonial__content {
  opacity: 0;
  transition: opacity 0.5s ease-out;

  @nest .c-full-width-testimonial--animate & {
    opacity: 1;
  }
}

.c-full-width-testimonial__button {
  transform: translate3d(0, 32px, 0);

  @nest .c-full-width-testimonial--animate & {
    transform: translate3d(0, 0, 0);
  }
}