.m-article-link__ratio {
  height: 0;
  padding-top: 58.42%;
}

.m-article-link__arrow {
  transform: translateY(-2px);
  transition: transform 0.2s ease-out;

  @nest [dir="rtl"] & {
    transform: translateY(-2px) rotate(180deg);
  }
}

.m-article-link:hover .m-article-link__arrow {
  transform: translate3d(5px, -2px, 0);

  @nest [dir="rtl"] & {
    transform: translate3d(-5px, -2px, 0) rotate(180deg);
  }
}
