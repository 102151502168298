.m-modal {
  pointer-events: none;
}

.m-modal--open {
  pointer-events: all;
}

.m-modal__dim {
  background-color: rgba(0 0 0 / 40%);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.m-modal--open .m-modal__dim {
  opacity: 1;
}

.m-modal__modal {
  left: 0;
  opacity: 0;
  top: 0;
  transform: translate3d(0, 30px, 0);
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}

@screen md {
  .m-modal__modal {
    max-width: 700px;
  }

  .m-modal__modal--large {
    max-width: 1348px;
  }
}

.m-modal--open .m-modal__modal {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.2s ease-out 0.2s, transform 0.2s ease-out 0.2s;
}

@screen md {
  .m-modal__modal {
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, calc(-50% + 30px), 0);
  }

  .m-modal--open .m-modal__modal {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0);
  }
}
