.c-cta-contact {
    position: relative;
    background: url("../../svg/forms/wave-contact-bottom.svg") no-repeat center / cover;
    z-index: 1;
    padding: 110px 0;
}

.c-cta-contact::after {
    content: '';
    background: url("../../svg/forms/wave-contact-top.svg") no-repeat center / cover;
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-cta-contact__testimonial-blockquote > span {
    font-size: 63px;
    line-height: 0;
    position: relative;
    top: 10px;
}

@media screen and (min-width: 768px) {
    .c-cta-contact {
        background-image: url("../../svg/forms/wave-contact-bottom.svg");
        padding: 10vw 0;
        margin-top: -50px;
    }

    .c-cta-contact::after {
        background-image: url("../../svg/forms/wave-contact-top.svg");
    }
}
