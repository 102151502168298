.c-the-menu__main {
  height: var(--c-the-header-height, 72px);
}

.c-the-menu__logo-graphic {
  height: 32px;
  width: 160px;
}

.c-the-menu__sub-list {
  @apply absolute top-0 inset-inline-0 w-full h-full bg-neutral-dark-gray z-10;

  transform: translate3d(100%, 0, 0);
  transition: transform 0.2s ease-out;

  @nest [dir="rtl"] & {
    transform: translate3d(-100%, 0, 0);
  }

  &.c-the-menu__sub-list--active {
    transform: translate3d(0, 0, 0);
  }
}

.c-the-menu__item {
  border-color: #909495aa;
}

.c-the-menu__list--secondary {
  background-color: #40454e;
}

.c-menu__socials-list {
  background-color: #40454e;
}

.c-the-menu__arrow-icon {
  transform: translateY(-2px);

  @nest [dir="rtl"] & {
    transform: translateY(-2px) rotate(180deg);
  }
}
