.c-image-copy-slides {
  @screen lg {
    --m-pinned-subnav-height: 72px;
  }
}

.c-image-copy-slides__main--image-align-inline_start {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-direction: row-reverse !important;
  }
}

.c-image-copy-slides__main--image-align-inline_end {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex-direction: row !important;
  }
}

.c-image-copy-slides__blocks--fade {
  height: calc(0.5 * (100vh - 2 * 0.75rem - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
  top: calc(0.75rem + var(--c-the-header-height, 72px));

  @screen md {
    height: calc(0.5 * (100vh - 2 * 1.5rem - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
    top: calc(1.5rem + var(--c-the-header-height, 72px));
  }

  @screen lg {
    height: calc(100vh - 2 * 1.5rem - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px));
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    inset-inline-end: 0;
    position: absolute !important;

    @nest .c-image-copy-slides.is-active & {
      position: fixed !important;
    }
  }
}

.c-image-copy-slides__block--fade {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 1 !important;
    pointer-events: auto !important;
    position: relative !important;
  }
}

.c-image-copy-slides__block--normal {
  height: calc(100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px));
  margin-top: calc(-0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
  padding-top: calc(0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)) + 0.75rem);

  @screen md {
    margin-top: calc(-0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)));
    padding-top: calc(0.5 * (100vh - var(--c-the-header-height, 72px) - var(--m-pinned-subnav-height, 56px)) + 1.5rem);
  }

  @screen lg {
    margin-top: 0;
    padding-top: 0;
  }
}

.c-image-copy-slides__body {
  max-width: 344px;
}
