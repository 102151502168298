.c-partial-text-masthead__spacer {
  height: 76px;
}

@screen md {
  .c-partial-text-masthead__spacer {
    height: 106px;
  }
}

@screen xl {
  .c-partial-text-masthead__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 473px;
    width: calc(50% - 250px);
  }

  .c-partial-text-masthead__right {
    bottom: 0;
    inset-inline-end: 0;
    position: absolute;
    top: 0;
    width: calc(50vw + 219px);
  }
}