div.anchor {
  display: block;
  position: relative;
  top: -140px;
  visibility: hidden;
}

.c-comparison-table {
  max-width: 1168px;

  @apply mx-auto;
}

.c-comparison-table__row-headers {
  display: none;

  @media (min-width: 640px) {
    display: flex;
  }
}

.c-comparison-table__row:nth-child(2) .c-comparison-table__row-headers {
  display: flex;
}

.c-comparison-table .a-svg {
  @apply mx-auto;
}

.c-comparison-table__footer-p {
  @apply w-40 font-semibold;

  font-size: 14px;
}

.c-comparison-table__row:last-child {
  border-bottom: 0;
}

.c-comparison-table__row-heading {
  line-height: 18px !important;
  padding-bottom: 13px;
  padding-top: 13px;
}

.c-comparison-table__row-svg-cont {
  padding-bottom: 13px;
  padding-top: 13px;

  @media (min-width: 640px) {
    padding-bottom: 16px;
    padding-top: 10px;
  }
}

.c-comparison-table_custom-stick {
  position: sticky;
  top: 72px;

  @media (min-width: 640px) {
    display: none;

    &.flex {
      display: flex;
    }
  }
}
