.a-textarea {
  @apply bg-white block border border-neutral-gray outline-none p-3 text-neutral-dark-gray transition-colors w-full;
}

.a-textarea:disabled {
  @apply opacity-50;
}

.a-textarea:required {
  @apply border-primary-teal;
}

.a-textarea.invalid {
  background-color: #fcfcc9;
  border-color: #fcb281;
}
