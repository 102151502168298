.c-find-a-doctor__background-image {
  object-position: 75% 50%;

  @screen md {
    object-position: 50% 50%;
  }
}

.c-find-a-doctor__container {
  min-height: calc(548 / 812 * 100vh);

  @screen lg {
    min-height: calc(558 / 900 * 100vh);
  }
}

.c-find-a-doctor__body {
  max-width: 621px;
}

.c-find-a-doctor__search-box {
  max-width: 813px;
}

.c-find-a-doctor__regions-box {
  max-width: 551px;
}

.c-find-a-doctor__additional-copy {
  font-size: 12px;
}
