.c-background-image-copy__container {
  min-height: calc(672 / 812 * 100vh);

  @screen lg {
    min-height: calc(700 / 900 * 100vh);
  }
}

.c-background-image-copy__background-image-fade,
.c-background-image-copy__background-image {
  @nest .c-background-image-copy__block--body-align-inline_start & {
    object-position: 60% 50%;

    @screen md {
      object-position: 50% 50%;
    }
  }

  @nest .c-background-image-copy__block--body-align-inline_end & {
    object-position: 40% 50%;

    @screen md {
      object-position: 50% 50%;
    }
  }

  @nest .c-background-image-copy__block--body-align-block_start & {
    @screen md {
      object-position: 50% 75%;
    }
  }

  @nest .c-background-image-copy__block--body-align-block_end & {
    @screen md {
      object-position: 50% 25%;
    }
  }
}

.c-background-image-copy__body {
  max-width: 358px;
}
