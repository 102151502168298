.c-content-with-side-image__wrapper {
    position: relative;
    background: no-repeat top center / cover;
    z-index: 1;
    padding: 70px 24px;
}

.c-content-with-side-image__wrapper::after {
    content: '';
    background: no-repeat top center / cover;
    position: absolute;
    top: 8px;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-content-with-side-image__default {
    background-image: url("../../svg/forms/wave-content-bottom.svg");
}

.c-content-with-side-image__default::after {
    background-image: url("../../svg/forms/wave-content-top.svg");
}

.c-content-with-side-image__dark {
    background-image: url("../../svg/forms/wave-content-bottom-dark-sm.svg");
    color: white;
}

.c-content-with-side-image__dark::after {
    background-image: url("../../svg/forms/wave-content-top-dark-sm.svg");
}

.c-content-with-side-image__content h5 {
    font-weight: theme("fontWeight.semibold");
    font-size: theme("fontSize.base");
    color: theme("colors.primary-teal")
}

.c-content-with-side-image__content ul {
    margin-top: 24px;
    font-size: theme('fontSize.lg');
}

.c-content-with-side-image__content ul > li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.c-content-with-side-image__content ul > li::before {
    content: url('../../svg/icons/fill/orange-check.svg');
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    min-width: 35px;
}

.m-image-wrapper > img, .m-image-wrapper > video {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

@media screen and (min-width: 768px) {
    .c-content-with-side-image__default {
        padding: 9vw 80px 7vw;
        background-image: url("../../svg/forms/wave-content-bottom.svg");
    }

    .c-content-with-side-image__default::after {
        background-image: url("../../svg/forms/wave-content-top.svg");
    }

    .c-content-with-side-image__dark {
        padding: 130px 80px;
        background-image: url("../../svg/forms/wave-content-bottom-dark.svg");
    }

    .c-content-with-side-image__dark::after {
        background-image: url("../../svg/forms/wave-content-top-dark.svg");
    }
}
@media screen and (max-width: 768px) {
    .c-content-with-image {
        margin-bottom: 15px;
    }
}
