.c-expandable-faqs-us__item {
    border-bottom: 1px solid theme('colors.neutral-gray');
}

.c-expandable-faqs-us__plus {
    @apply relative;

    height: 21px;
    width: 21px;
}

.c-expandable-faqs-us__icon {
    transform: rotate(180deg);
    transition: all ease-in-out 0.3s;
}

.c-expandable-faqs-us__item--expanded .c-expandable-faqs-us__icon {
    transform: rotate(0);
}

.c-expandable-faqs-us__answer {
    height: 0;
    max-width: 95%;
    overflow: hidden;
    transition: height 0.2s ease-out;
}

.c-expandable-faqs-us__answer::before {
    content: "";
    display: block;
    height: 1rem;
    width: 100%;
}

@media (max-width: 767px) {
    .c-expandable-faqs-us {
        margin-top: 0;
    }
}
