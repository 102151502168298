.c-procedure-steps {
 padding-top: 10%;
}

.procedure-steps__step--background-image {
  margin-left: 15%;
  max-width: 305px;
  min-height: 400px;
  height: 100%;
}

.c-procedure-steps__mobile {
  display: none;
}

.procedure-steps__step {
  padding: 5%;
}

.procedure-steps__step.bg-blue-wave {
  position: relative;
  background: url("../../svg/forms/wave-contact-bottom.svg") no-repeat center / cover;
  z-index: 1;
  margin-top: -55px;
  padding: 110px 5%;
}

.procedure-steps__step.bg-blue-wave::after {
  content: '';
  background: url("../../svg/forms/wave-contact-top.svg") no-repeat center / cover;
  position: absolute;
  top: 8px;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: -1;
}

.c-procedure-steps__right-side {
  width: 50%;
  max-width: 600px;
}

.c-procedure-steps__right-side > h3 {
  letter-spacing: 0.1em;
  padding-bottom: 10px;
}

.c-procedure-steps__right-side > a{
  display: block;
  min-width: 317px;
  font-size: 16px;
}

.c-procedure-steps__description strong{
  font-weight: 600;
}

.c-procedure-steps__description li {
  position: relative;
  padding-left: 15px;
}

.c-procedure-steps__description li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 8px;
}

.procedure-steps__step--background-image > div > .a-video {
  height: 500px;
  width: 300px;
}

.c-procedure-steps-wrapper > div > .a-video {
  background-color: transparent;
}

/* Stepper CSS */

:root {
  --circle-size: clamp(1rem, 4vw, 2rem);
  --spacing: clamp(0rem, 0vw, 0rem);
}

.c-stepper {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  position: relative;
}

.c-stepper__item:before {
  --size: 3rem;
  content: "";
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #10A8BA;
  margin: 0 auto 1rem;
}

ol.c-stepper > li:nth-child(5):before {
  margin: 0.35rem auto 1rem;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size) / 2);
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 7px;
  background-color: #F5F5F5;
  order: -1;
}

.c-stepper__title {
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
  max-width: 180px;
  margin: 0 auto;
}

.c-stepper__desc {
  color: grey;
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

.c-stepper__number {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1rem; 
}

.c-stepper__messageBox {
  background-color: #10A8BA;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: -55%;
  min-width: 100px;
  min-height: 58px;
  padding: 5px 8px;
  border-radius: 3px;
}

.c-stepper__messageBox p:first-child {
  font-size: 14px;
  font-weight: 300;
}

.c-stepper__messageBox-pointer {
  background-color: #10A8BA;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
  top: 62%;
}

.c-timeline {
  display: none;
}

.c-procedure-steps__mobile-sticky {
  display: none;
}



/* Responsive */

@media (max-width: 1024px) {

  .procedure-steps__step--background-image {
    margin-left: 5%;
  }
}

@media (max-width: 767px) {
  .c-procedure-steps {
    padding-top: 0;
   }

  .procedure-steps__step--background-image {
    margin: 0 auto;
    max-width: unset;
    min-height: unset;
  }

  .c-procedure-steps__right-side > div > ul {
    margin-left: 15px;
  }

  .c-procedure-steps__mobile {
    display: block;
  }

  .procedure-steps__step.bg-blue-wave {
    padding: 120px 5% 70px;
  }

  .procedure-steps__step {
    padding: 50px 5%;
  }

  .c-procedure-steps__right-side {
    max-width: unset;
    margin-top: 25px;
    width: 100%;
  }

  .c-procedure-steps__right-side > h3, .c-procedure-steps__right-side > h1 {
    display: none;
  }

  .procedure-steps__step {
    display: block;
  }

  .c-stepper {
    display: none;
  }

  .c-timeline {
    display: block;
    position: sticky;
    bottom: 0;
    padding-top: 5%;
    padding-left: 40%
  }
  
  .c-timeline__item {
    position: relative;
    display: flex;
    gap: 1.5rem;
    
  }
  
  .c-timeline__number {
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 2;
    color: white;
  }
  
  .c-timeline__item:last-child .c-timeline__content:before {
    display: none;
  }
  
  .c-timeline__content {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;
  }
  
  .c-timeline__content:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 7px;
      background-color: #F5F5F5;
    }
  
    .c-timeline__content:after {
      content: "";
      position: absolute;
      left: calc(0px - 11px);
      top: 0;
      width: 30px;
      height: 30px;
      background-color: #10A8BA;
      z-index: 1;
      border-radius: 50%;
    }
  
  
  .c-timeline__title {
    margin-top: 0.2rem;
    margin-left: 0.5rem;
  }

  .c-timeline__messageBox {
    background-color: #10A8BA;
    position: absolute;
    left: -80px;
    transform: translate(-50%, -50%);
    top: 20%;
    min-width: 100px;
    padding: 7px 8px;
    border-radius: 3px;
    text-align: center;
  }

  .c-timeline__messageBox-pointer:not(.active-step) {
    background-color: #10A8BA;
    height: 12px;
    width: 50px;
    position: absolute;
    right: -37%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
    top: 35%;
  }

  .c-timeline > li:nth-child(1) > .c-timeline__content > span {
    left: 1px;
  }
  
  time {
    text-align: end;
    flex: 0 0 100px;
    min-width: 0;
    overflow-wrap: break-word;
    padding-bottom: 1rem;
  }

  .c-procedure-steps__mobile-sticky {
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .c-procedure-steps__mobile-sticky > .white-box, .c-procedure-steps__mobile-sticky.open > div > .white-box  {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .c-procedure-steps__mobile-sticky > .white-box > p {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .c-procedure-steps__mobile-sticky > .white-box button,
  .c-procedure-steps__mobile-sticky.open > div > div > button {
    border: none;
    color: #10A8BA;
    z-index: 10;
  }

  .c-procedure-steps__mobile-sticky > .white-box button:hover,
  .c-procedure-steps__mobile-sticky.open > div > div > button:hover {
    background-color: transparent;
  }

  .c-procedure-steps__mobile-sticky > .blue-box,  .c-procedure-steps__mobile-sticky.open > div > .blue-box {
    background-color: #10A8BA;
    height: 100%;
    padding: 10px 5px;
    min-width: 120px;
    border-radius: 3px;
    color: white;
    text-align: center;
  }

  .c-procedure-steps__mobile-sticky > .blue-box  > p:first-child,
  .c-procedure-steps__mobile-sticky.open > div > .blue-box  > p:first-child {
    font-size: 14px;
  }

  .c-procedure-steps__mobile-sticky.open {
    height: 330px;
    width: 100%;
    background-color: white;
    flex-wrap: wrap;
  }

  .c-procedure-steps__mobile-sticky.open > div {
    width: 100%;
    height: 60px;
    display: flex;
  }

  .c-procedure-steps__mobile-sticky.open > div > .blue-box {
    background-color: #F5F5F5;
    color: #10A8BA;
  }

  .c-procedure-steps__mobile-sticky > div > .blue-box  > p:last-child {
    font-weight: 600;
    text-transform: capitalize;
  }

  .c-procedure-steps__mobile-sticky.open > div > .white-box {
    padding-left: 60px;
  }

  .c-procedure-steps__mobile-sticky.open > .active-step > .blue-box {
    background-color: #10A8BA;
    color: white;
  }

 .c-timeline__messageBox-pointer.active-step, .c-procedure-steps__mobile-sticky > .blue-box > .c-timeline__messageBox-pointer {
    z-index: 5;
    top: 22px;
    left: 110px;
    height: 20px;
    background-color: #10A8BA;
    width: 40px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  /* @media (min-width: 1300px) {
    .c-procedure-steps__right-side {
      max-width: 700px;
    }
  } */
 
}
