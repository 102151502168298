.c-500__body {
  background-image: url("/img/bg-500-mobile.jpg");

  @screen md {
    background-image: url("/img/bg-500.jpg");
  }
}

.c-500__content {
  max-width: 924px;
}

.c-500__logo-bar {
  height: 72px;
}

.c-500__logo {
  height: 30px;
  width: 100px;
}
