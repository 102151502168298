.c-faq-slider {
  @screen lg {
    background: linear-gradient(to left, transparent 0%, transparent 50%, theme("colors.primary-bright-teal") 50%);

    @nest [dir="rtl"] & {
      background: linear-gradient(to right, transparent 0%, transparent 50%, theme("colors.primary-bright-teal") 50%);
    }
  }
}

.c-faq-slider__container {
  max-width: 1128px;

  @screen lg {
    background: linear-gradient(to left, transparent 0%, transparent 30%, theme("colors.primary-bright-teal") 30%);
    min-height: calc(700 / 900 * 100vh);

    @nest [dir="rtl"] & {
      background: linear-gradient(to right, transparent 0%, transparent 30%, theme("colors.primary-bright-teal") 30%);
    }
  }
}

.c-faq-slider__heading {
  max-width: 452px;

  @screen lg {
    max-width: 500px;
  }
}

.c-faq-slider__slide-body {
  min-height: calc(330 / 812 * 100vh);

  @screen lg {
    min-height: auto;
  }
}

.c-faq-slider__slide-content {
  max-width: 487px;
}

.c-faq-slider__slide-attribution {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;

  @screen sm {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
  }
}

.c-faq-slider__pagination-dots {
  max-width: 487px;

  @screen lg {
    max-width: 564px;
  }
}