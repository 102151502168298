.c-questions-and-answers__heading {
  max-width: 340px;
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out 0.1s;
}

.c-questions-and-answers--animate .c-questions-and-answers__heading {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c-questions-and-answers__qas {
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: opacity 0.3s ease-out 0.2s, transform 0.3s ease-out 0.4s;
}

.c-questions-and-answers--animate .c-questions-and-answers__qas {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.c-questions-and-answers__item {
  margin-top: 3.5rem;
}

@screen md {
  .c-questions-and-answers__item {
    margin-top: 5rem;
  }
}

.c-questions-and-answers__item:first-child {
  margin-top: 0;
}
