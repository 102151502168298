.c-legacy-menu-icl__content {
  background-color: rgba(0, 0, 0, 0.8);
}

.c-legacy-menu-icl__bar {
  background-color: #57606c;
}

.c-legacy-menu-icl__item {
  background-color: #57606c;
}

.c-legacy-menu-icl__item--primary {
  background-color: #71ccd7;
}

.c-legacy-menu-icl__item--secondary {
  background-color: #3f454f;
}

.c-legacy-menu-icl__link,
.c-legacy-menu-icl__sublink {
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 0.78px;
  line-height: 22px;
}

.c-legacy-menu-icl__subitem {
  background-color: #3f454f;
}

.c-legacy-menu-icl__socials-list {
  background-color: #3f454f;
}

.c-legacy-menu-icl__socials-link {
  color: #909495;
}

.c-legacy-menu-icl__dropdown-search {
  background-color: #57606c;
  font-size: 13px;
}

.c-legacy-menu-icl__dropdown-search-button {
  font-size: 12px;
}

.c-legacy-menu-icl__dropdown-search-button-icon {
  height: 14px;
  width: 14px;
}

.c-legacy-menu-icl__dropdown-search-input {
  height: 34px;
}

.c-legacy-menu-icl__dropdown-search-input-button {
  height: 34px;
}

.c-legacy-menu-icl__dropdown-search-icon {
  width: 16px;
  height: 16px;
}

.c-legacy-menu-icl__dropdown-heading {
  color: #57606c;
}

.c-legacy-menu-icl__dropdown-menus {
  border-bottom: 1px solid #d9dedf;
}

.c-legacy-menu-icl__dropdown-menu-item {
  font-size: 13px;
  padding-top: 7px;
}

.c-legacy-menu-icl__dropdown-menu {
  margin-top: 48px;
}

.c-legacy-menu-icl__dropdown-menu:first-child {
  margin-top: 0;
}

.c-legacy-menu-icl__dropdown-menu-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 30px;
  background-color: #10a8ba;
}

.c-legacy-menu-icl__dropdown-submenu-item {
  font-size: 12px;
  color: #909495;
  line-height: 34px;
}

.c-legacy-menu-icl__dropdown-submenu-item:hover {
  color: #10a8ba;
}

.c-legacy-menu-icl__dropdown-column {
  width: 240px;

}

.c-legacy-menu-icl__dropdown-column:nth-child(2) {
  margin: 0 2.5rem;
}

.c-legacy-menu-icl__dropdown-more-links {
  columns: 3;
  column-gap: 2.5rem;
}

.c-legacy-menu-icl__dropdown-more-links-heading {
  color: #57606c;
}
