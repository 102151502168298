.c-article-masthead {
  @apply my-14;
}

@screen md {
  .c-article-masthead {
    @apply my-18;
  }
}

.c-article-masthead__picture-container {
  --container-padding-inline: 0;
  --container-max-width: var(--container-lg-client-max-width);
}