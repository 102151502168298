.a-svg {
  display: block;
}

.a-svg svg {
  height: inherit;
  width: inherit;
  display: block;
  margin: auto;
}

.a-svg.fill-current [fill] {
  fill: currentcolor;
}

.a-svg.stroke-current [stroke] {
  stroke: currentcolor;
}
