.c-kr-newsletter__background-image {
  object-position: 75% 50%;

  @screen md {
    object-position: 50% 50%;
  }
}

.c-kr-newsletter__body {
  max-width: 621px;
}

.c-kr-newsletter__search-box {
  max-width: 813px;
}

.c-kr-newsletter__regions-box {
  max-width: 551px;
}