.c-split-testimonial__content {
  max-width: 622px;
}

.c-split-testimonial__image-container {
  clip-path: inset(100% 0 0 0);
  transition: clip-path 0.5s ease-out;

  @nest .c-split-testimonial--animate-down & {
    clip-path: inset(0);
  }
}

.c-split-testimonial__image-container-horizontal {
  height: 330px;
  width: 100%;

  @screen lg {
    height: 668px;
    width: 38%;
  }
}

.c-split-testimonial__image-container-vertical {
  height: 518px;
  width: 100%;

  @screen lg {
    height: 668px;
    width: 28%;
  }
}

.c-split-testimonial__quote {
  opacity: 0;
  transition: opacity 0.5s ease-out;

  @nest .c-split-testimonial--animate & {
    opacity: 1;
  }
}

.c-split-testimonial__button {
  opacity: 0;
  transform: translate3d(0, 32px, 0);
  transition: all transform 0.5s ease-out 0.2s, opacity 0.5s ease-out 0.2s;
  background-color: transparent;
  color: inherit;

  @nest .c-split-testimonial--animate & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
