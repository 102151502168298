.a-video::before {
  content: "";
  display: block;
  width: 100%;
}

.a-video__horizontal::before {
  padding-top: 56.25%;
}

.a-video__vertical::before {
  padding-top: 177.77%;
}

.a-video__content > iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
