.c-copy-block {
  --h-color: theme("colors.neutral-dark-gray");

  @apply text-primary-gray my-14;
}

@screen md {
  .c-copy-block {
    @apply my-18;
  }
}
