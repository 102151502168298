.c-article-listing__trending-item {
  margin-top: 3.5rem;
  width: calc(100%);
}

.c-article-listing__trending-item:first-child {
  margin-top: 0;
}

@screen md {
  .c-article-listing__trending {
    margin: 0 -10px;
  }

  .c-article-listing__trending-item {
    margin: 0 10px;
    width: calc(50% - 20px);
  }
}

.c-article-listing__articles {
  margin: 10px -10px -10px;
}

@screen sm {
  .c-article-listing__articles {
    margin: 94px -10px -10px;
  }
}

.c-article-listing__articles-item {
  margin: 10px;
  width: calc(100% - 20px);
}

@screen sm {
  .c-article-listing__articles-item {
    width: calc(50% - 20px);
  }
}

@screen lg {
  .c-article-listing__articles-item {
    width: calc(33.3333% - 20px);
  }
}
