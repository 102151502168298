.c-counter__body {
  max-width: 358px;
}

.c-counter__heading,
.c-counter__copy,
.c-counter__button,
.c-counter__link {
  opacity: 0;
}

.c-counter__button,
.c-counter__link {
  transform: translateY(20px);
}

.c-counter__button {
  display: none !important;
}

.c-counter__link {
  transition: 3s 1s;
}

.c-counter__link-arrow {
  @nest [dir="rtl"] & {
    transform: rotate(180deg);
  }
}

.c-counter__animation {
  padding: 40px;
}

@screen lg {
  .c-counter__button {
    display: inline-block !important;
  }

  .c-counter__animation {
    padding: 0;
  }
}

.c-counter__container.is-active .c-counter__heading,
.c-counter__container.is-active .c-counter__copy,
.c-counter__container.is-active .c-counter__button,
.c-counter__container.is-active .c-counter__link {
  opacity: 1;
}

.c-counter__container.is-active .c-counter__button,
.c-counter__container.is-active .c-counter__link {
  transform: translateY(0);
}
