.c-locale-switcher-screen__logo {
  width: 195px;
  height: 19px;
}

.c-locale-switcher-screen__background {
  z-index: 1;
}

.c-locale-switcher-screen__background-ellipses {
  bottom: -92px;
  left: -185px;
  width: 644px;
}

@screen md {
  .c-locale-switcher-screen__background-image {
    max-width: 60%;
  }
}

.c-locale-switcher-screen__background-gradient {
  left: 0;
  top: 20%;
  width: 100%;
}

@screen md {
  .c-locale-switcher-screen__background-gradient {
    left: 40%;
    top: initial;
    width: 30%;
  }
}
