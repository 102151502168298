.c-testimonials-slider {
  @screen lg {
    background: linear-gradient(to right, transparent 0%, transparent 50%, theme("colors.secondary-orange") 50%);

    @nest [dir="rtl"] & {
      background: linear-gradient(to left, transparent 0%, transparent 50%, theme("colors.secondary-orange") 50%);
    }
  }
}

.c-testimonials-slider__container {
  max-width: 1128px;

  @screen lg {
    background: linear-gradient(to right, transparent 0%, transparent 30%, theme("colors.secondary-orange") 30%);
    min-height: calc(700 / 900 * 100vh);

    @nest [dir="rtl"] & {
      background: linear-gradient(to left, transparent 0%, transparent 30%, theme("colors.secondary-orange") 30%);
    }
  }
}

.c-testimonials-slider__heading {
  max-width: 452px;
}

.c-testimonials-slider__testimonial-body {
  min-height: calc(330 / 812 * 100vh);

  @screen lg {
    min-height: auto;
  }
}

.c-testimonials-slider-us .glide__slides {
  align-items: start;
}

.c-testimonials-slider__testimonial-content {
  max-width: 487px;
}

.c-testimonials-slider__testimonial-blockquote {
  &::before {
    color: #de742e;
    content: "“";
  }

  &::after {
    color: #de742e;
    content: "”";
  }
}

.c-testimonials-slider__testimonial-attribution {
  color: #de742e;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;

  @screen sm {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
  }

  &::before {
    content: "- ";
  }
}

.c-testimonials-slider__pagination-dots {
  max-width: 487px;

  @screen lg {
    max-width: 564px;
  }
}
