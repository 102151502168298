.m-image-wrapper {
    position: relative;
    z-index: 1;
}

.m-image-wrapper::after {
    content: '';
    position: absolute;
    width: 40%;
    height: 50%;
    z-index: -1;
}

.m-image-wrapper::before {
    content: '';
    position: absolute;
    width: 40%;
    height: 50%;
    background: white;
    z-index: -1;
}

.m-image-wrapper__sm::after, .m-image-wrapper__md::after {
    right: -10px;
    top: -10px;
}

.m-image-wrapper__sm::before, .m-image-wrapper__md::before {
    left: -10px;
    bottom: -10px;
}

.m-image-wrapper__type-white::after, .m-image-wrapper__type-white::before {
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

.m-image-wrapper__type-accent::after, .m-image-wrapper__type-accent::before {
    background: theme("colors.secondary-orange-dark");
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

@media screen and (min-width: 768px) {
    .m-image-wrapper__md::after {
        right: -10px;
        top: -10px;
    }

    .m-image-wrapper__md::before {
        left: -10px;
        bottom: -10px;
    }
}
