.c-expandable-faqs-evo__item {
    margin-bottom: 15px;
}

.c-expandable-faqs-evo__item button {
    background: white;
    border-radius: 10px;
    padding: 15px 25px 15px 30px;
}

.c-expandable-faqs-evo__icon {
    transform: rotate(180deg);
    transition: all ease-in-out 0.3s;
}

.c-expandable-faqs-evo__item--expanded .c-expandable-faqs-evo__icon {
    transform: rotate(0);
}

.c-expandable-faqs-evo__item--expanded .c-expandable-faqs-evo__answer {
    margin-top: 25px;
}

.c-expandable-faqs-evo__answer {
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
    padding: 0 30px;
    max-width: 95%;
}

@media (max-width: 767px) {
    .c-expandable-faqs-evo {
        margin-top: 0;
    }
}
