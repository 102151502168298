.c-the-header__nav-dropdown {
  @apply opacity-0;
  @apply pointer-events-none;
}

.c-the-header__nav-item:hover .c-the-header__nav-dropdown {
  @apply opacity-100;
  @apply pointer-events-auto;
}

.c-the-header__button {
  border-radius: 20px / 50%;
  padding: 6px 22px;
}

.c-the-header__progress-bar {
  height: 4px;
}

.c-the-header__progress-bar-bar {
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.2s ease-out;

  @nest [dir="rtl"] & {
    transform-origin: right;
  }
}
