.c-testimonial-masthead__spacer {
  height: 76px;
}

@screen md {
  .c-testimonial-masthead__spacer {
    height: 106px;
  }
}

.c-testimonial-masthead__heading-column {
  max-width: 505px;
}

.c-testimonial-masthead__copy {
  max-width: 653px;
}

.c-testimonial-masthead__image {
  height: 330px;
}

@screen md {
  .c-testimonial-masthead__image {
    height: 556px;
  }
}

.c-testimonial-masthead__expand-button {
  border-bottom: 1px solid rgba(255 255 255 / 20%);
  height: 64px;
}

.c-testimonial-masthead__scroll-button {
  padding: 0;
}

.c-testimonial-masthead__plus {
  @apply relative;

  height: 21px;
  width: 21px;
}

.c-testimonial-masthead__plus-bar {
  @apply w-full bg-neutral-white absolute;

  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.c-testimonial-masthead__plus-bar--vertical {
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.1s ease-out;
}

.c-testimonial-masthead__info--expanded .c-testimonial-masthead__plus-bar--vertical {
  transform: translateY(-50%) rotate(0);
}

.c-testimonial-masthead__info {
  transition: height 0.2s ease-out;
}