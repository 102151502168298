.c-stats__container {
  max-width: 1440px;
  min-height: calc(672 / 812 * 100vh);

  @screen lg {
    min-height: calc(700 / 900 * 100vh);
  }
}

.c-stats__stat:nth-child(n+2) {
  @apply border-t;

  @screen lg {
    @apply border-t-0;
  }
}

.c-stats__stat:nth-child(even) {
  @screen lg {
    @apply border-is;
  }
}

.c-stats__number {
  font-size: 64px;
  font-weight: 300;
  line-height: 1.25;

  @screen lg {
    font-size: 96px;
    font-weight: normal;
    line-height: 0.92;
  }
}

.c-stats__badge {
  max-width: 333px;
}

.c-stats__badge strong,
.c-stats__badge em {
  @apply text-primary-teal font-bold;
}
.c-hero-footer__heading p {
  font-weight: 500;
}
.c-hero-footer__heading strong {
  font-weight: 700;
}
