.c-small-testimonial-grid__item {
  margin-top: 0.75rem;
  width: 100%;
}

.c-small-testimonial-grid__item--full-width {
  width: 100%;
}

@screen lg {
  .c-small-testimonial-grid__item {
    width: calc(50% - (0.75rem * 2));
  }

  .c-small-testimonial-grid__item--full-width {
    width: calc(100% - (0.75rem * 2));
  }
}
