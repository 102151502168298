.c-hero__wrapper {
    position: relative;
    background: no-repeat center / cover;
    padding: 100px 24px 0;
    margin: 90px 0 0;
    color: white;
}

.c-hero__wrapper::after {
    content: '';
    background: no-repeat center / cover;
    position: absolute;
    top: 20px;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: -1;
}

.c-hero__bg-lg {
    background-image: url("/img/bg-bottom-wave-lg.svg");
}

.c-hero__bg-lg::after {
    background-image: url("/img/bg-top-wave-lg.svg");
}

.c-hero__bg-md::after {
    top: 0;
    background-image: url("../../svg/forms/wave-hero-top-sm.svg");
}

.c-hero__bg-md {
    padding: 75px 10px;
    background-image: url("../../svg/forms/wave-hero-bottom-sm.svg");
}

.c-hero__wrapper .c-hero__columns {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
    margin-top: 30px;
}

.c-hero__image-wrapper > img, .c-hero__image-wrapper > div {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

.c-hero__featured-content {
    background: theme("colors.neutral-white");
    border-radius: 10px;
    padding: 24px 36px;
    color: theme("colors.neutral-dark-gray");
}

.c-hero__featured-content ul {
    list-style: disc;
    padding-left: 12px;
}

.c-hero__featured-content h3 {
    color: theme("colors.primary-dark-teal");
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
}

.c-hero__scroll-btn-wrapper {
    position: relative;
    left: 0;
    right: 0;
    bottom: 10px;
    z-index: 6;
}

.c-hero__scroll-btn-wrapper .c-hero__scroll-btn {
    display: block;
    margin: auto;
    width: 68px;
    height: 68px;
    border-radius: 100%;
    color: theme("colors.primary-dark-teal");
    background: theme("colors.neutral-white");
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.3);
}

.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 20%;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 40px;
    border: 1px solid #888;
    border-radius: 0.5em;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 600px !important;
}

.modal-content > p > span {
     font-style: italic;
}

.close {
    position: absolute;
    top: -20px;
    right: -10px;
    float: right;
    font-size: 32px;
    width: 55px;
    height: 55px;

}

.close:hover,
.close:focus {
    text-decoration: none;
    cursor: pointer;
}

.only-desktop {
    display: none;
  }

/* Responsive */

@media screen and (min-width: 768px) {
    .c-hero__wrapper {
        padding: 100px 4.5rem 0;
    }

    .c-hero__wrapper, .c-hero__wrapper::after {
        background-position: top center;
    }

    .c-hero__wrapper .c-hero__columns {
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 60px;
    }

    .c-hero__wrapper .c-hero__image-wrapper {
        width: max-content;
        max-width: 55%;
    }

    .c-hero__featured-content {
        margin-top: 40px;
    }

    .c-hero__bg-lg::after {
        top: 20px;
        background-image: url("/img/bg-top-wave-lg.svg");
    }

    .c-hero__bg-md::after {
        top: 0;
        background-image: url("../../svg/forms/wave-hero-bottom-md.svg");
    }

    .c-hero__bg-md {
        padding: 4vw 24px 5vw;
        background-image: url("/img/bg-bottom-wave-md.svg");
        margin-top: 110px;
    }

    .c-hero__bg-md.small-hero {
        padding: 75px 10px 20px 100px;
        background: url("../../svg/forms/wave-contact-bottom.svg") no-repeat center / cover;
        z-index: 1;
    }

      .c-hero__bg-md.small-hero::after {
        content: '';
        background: url("../../svg/forms/wave-contact-top.svg") no-repeat center / cover;
        position: absolute;
        top: 8px;
        bottom: 20px;
        left: 0;
        width: 100%;
        z-index: -1;
      }

      .modal-content {
        background-color: #fefefe;
        margin: 5% auto;
        padding: 50px;
        border: 1px solid #888;
        border-radius: 0.5em;
        width: 60%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 600px !important;
    }

      .only-desktop {
        display: block;
      }
}

@media screen and (min-width: 1000px) {
    .c-hero__bg-md {
        background-size: 140%;
        background-position: top center;
    }
    .c-hero__wrapper .c-hero__columns {
        gap: 90px;
    }
}

/* Hero Footer */
.c-hero-footer {
    position: relative;
    background: url("../../svg/forms/wave-hero-footer-sm.svg") no-repeat center / cover;
    padding: 60px 0 30px;
    margin-top: -65px;
    color: white;
}

.c-hero-footer__heading {
    font-size: theme('fontSize.sm');
    text-transform: uppercase;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .c-hero-footer {
        background-image: url("../../svg/forms/wave-hero-footer.svg");
        padding: 45px 0;
        margin-top: -50px;
    }
    .c-hero-footer__heading {
        font-size: theme('fontSize.lg');
        font-weight: theme('fontWeight.semibold');
    }
}
