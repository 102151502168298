.c-legacy-safety-info__html-formatting p {
  margin-bottom: 12px;
}

.c-legacy-safety-info__html-formatting b,
.c-legacy-safety-info__html-formatting strong {
  font-weight: 600;
}

.c-legacy-safety-info__html-formatting i {
  font-style: italic;
}

.c-legacy-safety-info__html-formatting ul {
  list-style-type: disc;
  margin-bottom: 12px;
  margin-left: 24px;
}

.c-legacy-safety-info__html-formatting ol {
  list-style-type: decimal;
  margin-bottom: 12px;
  margin-left: 24px;
}
