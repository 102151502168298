.c-video-slider__container {
  max-width: 1128px;
  padding: 0 20px;
  
}

.c-video-slider__container .glide__slides {
  align-items: center;
}

@media (max-width: 1024px) {
  .dots-below {
    position: absolute;
   bottom: -90px;
  }
}
