.m-pagination-dots__arrow {
  @apply text-primary-teal;

  @nest .m-pagination-dots--theme_secondary-orange & {
    color: #de742e;
  }
}

.m-pagination-dots__dot {
  @apply bg-primary-gray;

  &.is-active {
    @apply bg-primary-teal;
  }

  @nest .m-pagination-dots--theme_secondary-orange & {
    background-color: #de742e;

    &.is-active {
      @apply bg-neutral-white;
    }
  }
}

.m-pagination-dots__arrow-icon {
  @nest [dir="rtl"] & {
    transform: rotate(180deg);
  }
}

.m-pagination-arrow-circle > svg {
  height: 42px;
  width: 42px;
}

.m-pagination-dots__arrow.arrow-right {
  position: absolute;
  right: -2.5%;
  bottom: 80px;
}

.m-pagination-dots__arrow.arrow-left {
  position: absolute;
  left: -1.5%;
  bottom: 80px;
}

.m-pagination-dots__arrow.arrow-right > span:before, .m-pagination-dots__arrow.arrow-left > span:before {
  position: absolute;
  right: 15px;
}

/* responsive */

@media (max-width: 1024px) {
  .m-pagination-dots__arrow.arrow-right, .m-pagination-dots__arrow.arrow-left {
    display: none;
  }
}
