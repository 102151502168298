.c-comparison-table__row__us > div:first-of-type, .c-comparison-table__row-accent {
    position: relative;
}

.c-comparison-table__row__us > div:first-of-type:not(.empty)::after, .c-comparison-table__row-accent::after {
    content: '';
    display: block;
    width: 100%;
    height: 142%;
    border-left-width: 2px;
    border-right-width: 2px;
    border-color: #FFA87A;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.no-shadow::after {
    box-shadow: none !important;
}

.c-comparison-table__row__us:last-of-type > div:first-of-type:not(.empty)::after {
    border-bottom-width: 2px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.c-comparison-table__row__us:last-of-type {
    border-bottom-width: 1px;
}

.c-comparison-table__row-accent::after {
    top: -30px;
    border-top-width: 2px;
    height: 276%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.c-comparison-table__row__us > div:first-of-type > *, .c-comparison-table__row-accent > * {
    position: relative;
    z-index: 3;
}

.c-comparison-table__row-svg-cont svg > path {
    stroke-width: 3px;
}

.c-comparison-table__row__us-svg-cont {
    padding: 2px 0 8px;
}
