.c-legacy-footer-icl {
  background-color: #2e3030;
  color: #909495;
  letter-spacing: 0.78px;
}

.c-legacy-footer-icl a {
  color: #d9dedf;
  transition: color 0.2s ease-out;
}

.c-legacy-footer-icl a:hover {
  color: #fff;
}

.c-legacy-footer-icl__primary {
  font-size: 13px;
}

.c-legacy-footer-icl__secondary {
  font-size: 12px;
  border-top: 1px solid rgba(217, 222, 223, 0.1);
}

@media (min-width: 1280px) {
  .c-legacy-footer-icl__secondary {
    border-top: none;
  }
}

.c-legacy-footer-icl__primary-link {
  margin-left: 32px;
}

.c-legacy-footer-icl__primary-link:first-child {
  margin-left: 0;
}

.c-legacy-footer-icl__secondary-link {
  display: inline-block;
  margin-left: 12px;
  position: relative;
}

.c-legacy-footer-icl__secondary-link::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 80%;
  background-color: #909495;
  top: 50%;
  transform: translateY(-50%);
  left: -8px;
}

.c-legacy-footer-icl__secondary-link:first-child {
  margin-left: 0;
}

.c-legacy-footer-icl__secondary-link:first-child::before {
  display: none;
}

.c-legacy-footer-icl__social-link {
  width: 25px;
  height: 25px;
  margin-left: 40px;
}

@media (min-width: 1280px) {
  .c-legacy-footer-icl__social-link {
    margin-left: 14px;
  }
}

.c-legacy-footer-icl__social-link:first-child {
  margin-left: 0;
}

a.c-legacy-footer-icl__social-link {
  color: #909495;
}

.c-legacy-footer-icl__social-icon {
  width: 100%;
}
