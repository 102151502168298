.c-wtw-slides__slide {
  background-color: #f5fbf8;
}

@screen md {
  .c-wtw-slides__slide {
    height: 750px;
  }
}

.c-wtw-slides__slide-container {
  max-width: 1440px;
}

.c-wtw-slides__slide-copy {
  max-width: 360px;
}
