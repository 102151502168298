.a-select {
  @apply appearance-none bg-white block border border-neutral-gray outline-none p-3 rounded-none text-neutral-dark-gray transition-colors w-full;

  background: #fff url("../../svg/icons/fill/arrow-down.svg") calc(100% - 10px) 50% / 34px no-repeat;
  padding-inline-end: 40px;

  @nest [dir="rtl"] & {
    background-position: 10px 50%;
  }
}

.a-select.regions {
  @apply pl-6 font-bold border-2 rounded-full text-button text-primary-gray;
}

.a-select option {
  @apply bg-neutral-white text-neutral-dark-gray;
}

.a-select:disabled {
  @apply opacity-50;
}

.a-select:required {
  @apply border-primary-teal;
}

.a-select.invalid {
  background-color: #fcfcc9;
  border-color: #fcb281;
}
