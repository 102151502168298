.c-image-copy-blocks__tile {
  min-height: calc(330 / 812 * 100vh);

  @screen lg {
    min-height: calc(700 / 900 * 100vh);
  }
}

.c-image-copy-blocks__tile--media {
  clip-path: inset(100% 0 0 0);
  transition: clip-path 0.5s 0.3s;

  @nest .c-image-copy-blocks__block.is-active-down & {
    clip-path: inset(0);
    transition: clip-path 0.5s;
  }
}

.c-image-copy-blocks__background-image,
.c-image-copy-blocks__background-video {
  clip-path: inset(100% 0 0 0);
  transition: clip-path 0.5s;

  @nest .c-image-copy-blocks__block.is-active-down & {
    clip-path: inset(0);
    transition: clip-path 0.5s 0.3s;
  }
}

.c-image-copy-blocks__body {
  max-width: 358px;
}
