.c-expandable-faqs__item {
  border-bottom: 1px solid #c4c4c4;
}

.c-expandable-faqs__plus {
  @apply relative;

  height: 21px;
  width: 21px;
}

.c-expandable-faqs__plus-bar {
  @apply w-full bg-primary-teal absolute;

  height: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.c-expandable-faqs__plus-bar--vertical {
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.1s ease-out;
}

.c-expandable-faqs__item--expanded .c-expandable-faqs__plus-bar--vertical {
  transform: translateY(-50%) rotate(0);
}

.c-expandable-faqs__answer {
  height: 0;
  max-width: 700px;
  overflow: hidden;
  transition: height 0.2s ease-out;
}

.c-expandable-faqs__answer::before {
  content: "";
  display: block;
  height: 1rem;
  width: 100%;
}
