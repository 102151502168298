.c-full-width-video__poster {
  transform: scale(1.1);
  transition: transform 0.4s ease-out;
}

.c-full-width-video--animate .c-full-width-video__poster {
  transform: scale(1);
}

.c-full-width-video__button {
  border-radius: 9999px;
}